
import { defineComponent, PropType, ref, onMounted, watch } from "vue";
import isPlainObject from "lodash/isPlainObject";
import FormItem from "@/shared/components/Forms/FormItem.vue";
import { useVModel } from "vue-composable";

export type DropdownOption =
  | string
  | {
      label: string;
      value: string;
      disabled?: boolean;
      title?: unknown;
    };

export default defineComponent({
  inheritAttrs: false,
  props: {
    options: {
      type: Array as PropType<DropdownOption[]>,
      required: true,
    },
    isFocusedModel: { type: Boolean, default: false },
    multiple: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    selectSingleOption: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ["update:value", "on-custom-blur-handler", "update:isFocusedModel"],
  setup(props, { emit, attrs }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _attrs: any = attrs;
    const isFocused = useVModel(props, "isFocusedModel");
    // We need this internally in case no props is passed for [isFocusedModel]
    // [useVModel] does not automatically create a reactive variable if there is no props
    const isFocusedInternal = ref();
    const checkAutoComplete = ref();
    // Added to force the ant-select to disable
    // since formdirectives disabled property
    // doesn't work properly for this component
    const isDisabled = ref(false);

    const focusHandler = () => {
      isFocused.value = true;
      isFocusedInternal.value = true;
    };

    const blurHandler = () => {
      isFocused.value = false;
      isFocusedInternal.value = false;
      emit("on-custom-blur-handler");
    };

    // used to programmatically update value
    const changeHandler = (value) => {
      emit("update:value", value);
      setTimeout(() => {
        // Call the change handler manually to trigger validation updates
        if (_attrs?.onChange) {
          _attrs?.onChange(value);
        }
      }, 300);
    };

    /**
     * On mount, check if selectSingleOption option is enabled and option length is just 1
     */
    onMounted(() => {
      if (props.selectSingleOption && props.options.length === 1) {
        const firstValue = props.options[0];
        // check if first value is an object value-text pair
        const value =
          typeof firstValue === "object" ? firstValue.value : firstValue;
        changeHandler(value);
      }
    });

    /**
     * Reselect on items changed
     *
     * Note: This will not re-trigger when leaving and going back to the page, see onMounted
     */
    watch(
      () => props.options,
      (newOptions) => {
        // check if selectSingleOption is enabled
        if (props.selectSingleOption && newOptions.length === 1) {
          const firstValue = newOptions[0];
          // check if first value is an object value-text pair
          const value =
            typeof firstValue === "object" ? firstValue.value : firstValue;
          changeHandler(value);
        }
      }
    );

    /**
     * This filter is case insensitive
     */
    const filterOption = (input: string, option: DropdownOption) => {
      let passOption: string | boolean | undefined = true;

      if (typeof option === "string") {
        passOption =
          (option && option.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
          (option && option.toLowerCase().indexOf(input.toLowerCase()) >= 0);
      } else if (typeof option.value === "string") {
        // Return matching label, but excluding disabled options
        passOption =
          (!option.disabled &&
            option.label &&
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
          (option.value &&
            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0);
      } else {
        passOption =
          !option.disabled &&
          option.label &&
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }

      return passOption;
    };

    return {
      focusHandler,
      isFocused,
      isFocusedInternal,
      blurHandler,
      checkAutoComplete,
      filterOption,
      changeHandler,
      isDisabled,
      mode: props.multiple ? "multiple" : undefined,
    };
  },
  methods: {
    isPlainObject,
  },
  components: {
    FormItem,
  },
});
