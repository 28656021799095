import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_FormItem = _resolveComponent("FormItem")!

  return (_openBlock(), _createBlock(_component_FormItem, {
    label: _ctx.$attrs.label,
    name: _ctx.$attrs.name,
    value: _ctx.$attrs.value,
    rules: _ctx.$attrs.rules,
    extra: _ctx.$attrs.extra,
    help: _ctx.$attrs.help,
    class: "input-date-range"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_range_picker, _mergeProps({
        value: _ctx.localValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
        class: "input-date-range__range-picker"
      }, _ctx.$attrs, {
        disabled: _ctx.$attrs.disabled,
        placeholder: [_ctx.t('From'), _ctx.t('To')],
        separator: " "
      }), null, 16, ["value", "disabled", "placeholder"])
    ]),
    _: 1
  }, 8, ["label", "name", "value", "rules", "extra", "help"]))
}