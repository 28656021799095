
import { defineComponent, reactive, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import PoolList from "@/shared/components/List/PoolList/PoolList.vue";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import { Moment } from "moment";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import InputDropdown from "@/shared/components/Forms/InputDropdown.vue";
import InputDateRange from "@/shared/components/Forms/InputDateRange.vue";
import LayoutMain from "@/shared/components/Layouts/LayoutMain.vue";
import { useBreakpoints } from "@/shared/utils/breakpoints";

type FilterQueryType = {
  queryText: string;
  categories: string[];
  dateRange: Moment[];
  pageSize: number;
};

const mockCategories = [
  {
    label: "All categories",
    value: "*",
  },
  {
    label: "Non profit",
    value: "non_profit",
  },
];

export default defineComponent({
  components: {
    PoolList,
    FormGroup,
    InputText,
    InputDropdown,
    InputDateRange,
    LayoutMain,
  },
  props: {},
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const { isMobileDown } = useBreakpoints();

    const filterFormState = reactive<FilterQueryType>({
      queryText: "",
      categories: ["*"],
      dateRange: [],
      pageSize: 100,
    });

    watch(filterFormState, (val) => console.log(val));

    const handleShowPoolDetails = (rowRecord) => {
      if (rowRecord?.hash) {
        router.push({
          name: routeNames.pool,
          params: {
            pool_hash: rowRecord?.hash,
          },
        });
      }
    };

    const filterFormLayout = computed(() =>
      isMobileDown.value ? "vertical" : "inline"
    );

    return {
      t,
      filterFormState,
      handleShowPoolDetails,
      categoriesOptions: mockCategories,
      filterFormLayout,
    };
  },
});
