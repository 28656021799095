
import { defineComponent, PropType } from "vue";
import FormItem from "@/shared/components/Forms/FormItem.vue";
import { useI18n } from "vue-i18n";
import { useVModel } from "vue-composable";
import { Moment } from "moment";

export default defineComponent({
  components: {
    FormItem,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: Array as PropType<Moment[]>,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const localValue = useVModel(props, "value");

    return {
      t,
      localValue,
    };
  },
});
