import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_InputDateRange = _resolveComponent("InputDateRange")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_PoolList = _resolveComponent("PoolList")!
  const _component_LayoutMain = _resolveComponent("LayoutMain")!

  return (_openBlock(), _createBlock(_component_LayoutMain, {
    class: "pools",
    title: _ctx.t('All pools')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormGroup, {
        class: "pools__form-filter",
        model: _ctx.filterFormState,
        layout: _ctx.filterFormLayout
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputText, {
            value: _ctx.filterFormState.queryText,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterFormState.queryText) = $event)),
            name: "queryText",
            placeholder: "Search by name"
          }, null, 8, ["value"]),
          _createVNode(_component_InputDropdown, {
            value: _ctx.filterFormState.categories,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterFormState.categories) = $event)),
            class: "pools__form-filter-dropdown",
            options: _ctx.categoriesOptions
          }, null, 8, ["value", "options"]),
          _createVNode(_component_InputDateRange, {
            value: _ctx.filterFormState.dateRange,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterFormState.dateRange) = $event)),
            class: "pools__form-filter-date-range"
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 8, ["model", "layout"]),
      _createVNode(_component_PoolList, {
        "page-size": _ctx.filterFormState.pageSize,
        "onUpdate:page-size": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterFormState.pageSize) = $event)),
        "query-filters": _ctx.filterFormState,
        "onUpdate:query-filters": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filterFormState) = $event)),
        class: "pools-pool-list",
        "show-date-column": "",
        onOnRowClick: _ctx.handleShowPoolDetails
      }, null, 8, ["page-size", "query-filters", "onOnRowClick"])
    ]),
    _: 1
  }, 8, ["title"]))
}